<template>
    <div class="home">

        <section class="encabezado -aula-mentoring">

            <h1>Aula mentoring</h1>

            <div class="botones row-center">
                <a @click="fullscreen" class="play">
                    <img src="/img/play.svg" alt="Play">
                </a>
            </div>

        </section>

        <!-- <slider-empresas></slider-empresas> -->


        <section class="banda">

            <div class="container column-center">


                <h2 class="title">Qué es Aula Mentoring</h2>

                <h3>Acercamos el mentoring grupal a las aulas</h3>

                <p>Aula Mentoring es un espacio creado para impartir talleres gratuitos a través de la técnica del
                    mentoring grupal, con el fin de facilitar a los participantes una mayor información acerca de un
                    sector determinado, o sobre habilidades para potenciar su desarrollo profesional. Está destinado a
                    todos los participantes del Programa Mentoring Alumni UC3M que deseen ampliar sus conocimientos así
                    como sus competencias personales y profesionales. Será impartido por mentores de forma altruista,
                    participativa y dinámica.</p>

            </div>
        </section>


        <section class="banda -darkblue">

            <div class="container column-center">


                <h2 class="title -white">¿Quieres colaborar como mentor/a ponente en los próximos talleres?</h2>

                <h3>Participa de forma voluntaria y ayúdanos a impulsar el talento de estudiantes y egresados/as UC3M.</h3>

                <router-link :to="$t('enlaces.contacto')+'/aula-mentoring'" class="btn -skull -white">Quiero ser ponente</router-link>

            </div>
        </section>


        <section class="galeria -lightblue">
            <div class="container">
                <h2 class="title">Próximos talleres</h2>
                <p>Los talleres se programan periódicamente en función de la demanda de sectores recibida o temas de
                    interés sugeridos, con una duración aproximada de una hora, en el que mentores
                    invitados de forma altruista, participativa y dinámica tratarán temáticas que puedan resultar de
                    interés al colectivo UC3M.</p>
                <a href="https://www.uc3m.es/alumni/programa-mentoring/aula-mentoring" target="_blank"
                    class="btn -skull">Programación e inscripción</a>
            </div>
            <galeria :id="'home'"></galeria>

        </section>

        <section class="video-hide" v-if="showvideo">
            <iframe id="video" class="video" src="https://www.youtube.com/embed/IxJF5ipZNFk?autoplay=1&loop=1" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>

            <a @click="showvideo = false" class="close">✕</a>
        </section>

    </div>
</template>

<script>

    // import sliderempresas from '@/components/slider-empresas';
    import galeria from '@/components/galeria';

    export default {
        name: 'Home',
        data() {
            return {
                showvideo : false
            }
        },
        methods: {
            fullscreen() {
                this.showvideo = true;
            }
        },
        components: {
            // 'slider-empresas': sliderempresas,
            'galeria': galeria
        }
    }
</script>